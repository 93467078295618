import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'
import importIcon from '../../../assets/materialUI/import.svg'
import plusWhite from '../../../assets/materialUI/plusWhite.svg'
import plusGrey from '../../../assets/materialUI/plusGrey.svg'
import arrowRightWhite from '../../../assets/materialUI/arrowRightWhite.svg'
import arrowRightBlue from '../../../assets/materialUI/arrowRightBlue.svg'
import arrowRightDisabled from '../../../assets/materialUI/arrow-right-disabled.svg'
import checkmarkBlue from '../../../assets/materialUI/checkmark-blue.svg'
import arrowLeft from '../../../assets/materialUI/arrowLeft.svg'
import arrowRight from '../../../assets/materialUI/arrowRight.svg'
import markRightBlue from '../../../assets/materialUI/mark-right-blue.svg'
import uploadGlb from '../../../assets/materialUI/uploadGlb.svg'
import copyIcon from '../../../assets/materialUI/copy.svg'
import arrowRightLink from '../../../assets/materialUI/arrow-right-link.svg'
import showDetailsIcon from '../../../assets/materialUI/show-details.svg'
import crossIcon from '../../../assets/materialUI/cross.svg'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    default: true
    import: true
    plus: true
    filter: true
    arrow: true
    transparent: true
    transparentArrow: true
    transparentCheckmark: true
    uploadImage: true
    uploadProfileImage: true
    loading: true
    previous: true
    next: true
    uploadGlb: true
    transparentBeforeArrow: true
    copyKey: true
    copyKeyDone: true
    link: true
    cross: true
    'show-details': true
  }
}
const muiButtons: {
  defaultProps?: ComponentsProps['MuiButton']
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton']
  variants?: ComponentsVariants['MuiButton']
} = {
  variants: [
    {
      props: { variant: 'default' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        background: 'var(--color-blue)',
        borderRadius: '21px',
        fontWeight: '700',
        padding: '13px 24px',
        width: 'fit-content',
        height: '42px',
        color: 'var(--color-white)',
        ':hover': {
          background: 'var(--color-blue-dark)',
        },
        '&:disabled': {
          background: 'var(--color-gray)',
          color: 'var(--color-white)',
          opacity: '0.3',
        },
      },
    },
    {
      props: { variant: 'import' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        '::before': {
          content: '""',
          backgroundImage: `url(${importIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          border: '2px',
          width: '11px',
          height: '11px',
          marginRight: '12px',
        },
      },
    },
    {
      props: { variant: 'plus' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        background: 'var(--color-blue)',
        borderRadius: '21px',
        height: '42px',
        fontWeight: '700',
        alignItems: 'baseline',
        padding: '13px 24px',
        color: 'var(--color-white)',
        ':hover': {
          background: 'var(--color-blue-dark)',
        },
        '::before': {
          content: '""',
          backgroundImage: `url(${plusWhite})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          top: '1px',
          width: '11px',
          height: '11px',
          marginRight: '12px',
        },
      },
    },
    {
      props: { variant: 'filter' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        fontWeight: '500',
        background: 'var(--color-white)',
        borderRadius: '4px',
        padding: '8px 14px 8px 10px',
        color: 'var(--color-dark)',
        boxShadow: '0px 2px 20px 0px rgba(34, 45, 51, 0.08)',
        '::before': {
          content: '""',
          backgroundImage: `url(${plusGrey})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          border: '2px',
          width: '16px',
          height: '16px',
          marginRight: '10px',
        },
      },
    },
    {
      props: { variant: 'arrow' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        background: 'var(--color-blue)',
        borderRadius: '21px',
        fontWeight: '700',
        height: '42px',
        padding: '13px 24px',
        width: 'fit-content',
        color: 'var(--color-white)',
        textWrap: 'nowrap',
        ':hover': {
          background: 'var(--color-blue-dark)',
        },
        '::after': {
          content: '""',
          backgroundImage: `url(${arrowRightWhite})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          top: '-1px',
          width: '11px',
          height: '9px',
          marginLeft: '6px',
        },
        '&:disabled': {
          background: 'var(--color-gray)',
          color: 'var(--color-white)',
          opacity: '0.3',
        },
      },
    },
    {
      props: { variant: 'transparent' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        lineHeight: 'var(--line-height-sm)',
        border: '2px solid #49ACCF',
        borderRadius: '21px',
        height: '42px',
        fontWeight: '700',
        padding: '0 24px',
        color: 'var(--color-blue)',
        transition: '.2s ease-in-out',
        marginTop: '1px',
        ':hover': {
          color: 'var(--color-blue-dark)',
          border: '2px solid #128CB8',
          background: 'var(--color-gray-light)',
          transition: '.2s ease-in-out',
        },
        '&:disabled': {
          background: 'var(--color-white)',
          color: 'var(--color-gray)',
          borderColor: 'var(--color-gray)',
          opacity: '0.3',
        },
      },
    },
    {
      props: { variant: 'transparentArrow' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        lineHeight: 'var(--line-height-sm)',
        border: '2px solid #49ACCF',
        borderRadius: '21px',
        height: '42px',
        fontWeight: '700',
        padding: '0 24px',
        color: 'var(--color-blue)',
        transition: '.2s ease-in-out',
        ':hover': {
          color: 'var(--color-blue-dark)',
          border: '2px solid #128CB8',
          background: 'var(--color-blue-light)',
          transition: '.2s ease-in-out',
        },
        '::after': {
          content: '""',
          backgroundImage: `url(${arrowRightBlue})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          top: '0',
          width: '11px',
          height: '9px',
          marginLeft: '10px',
        },
        '&:disabled': {
          color: 'var(--color-gray-light)',
          border: '2px solid var(--color-gray-light)',
        },
        ':disabled::after': {
          backgroundImage: `url(${arrowRightDisabled})`,
        },
      },
    },
    {
      props: { variant: 'transparentBeforeArrow' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        lineHeight: 'var(--line-height-sm)',
        border: 'none',
        fontWeight: '500',
        padding: '17px 0',
        color: 'var(--color-blue)',
        transition: '.2s ease-in-out',
        justifyContent: 'flex-start',
        ':hover': {
          color: 'var(--color-blue-dark)',
          transition: '.2s ease-in-out',
          background: 'transparent',
        },
        '::before': {
          content: '""',
          backgroundImage: `url(${markRightBlue})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          top: '0',
          width: '16px',
          height: '16px',
          marginLeft: '0',
          marginRight: '10px',
        },
      },
    },
    {
      props: { variant: 'transparentCheckmark' },
      style: {
        marginLeft: '11px',
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        lineHeight: 'var(--line-height-sm)',
        border: '2px solid #49ACCF',
        borderRadius: '21px',
        height: '42px',
        fontWeight: '700',
        padding: '0 24px',
        color: 'var(--color-blue)',
        transition: '.2s ease-in-out',
        ':hover': {
          color: 'var(--color-blue-dark)',
          border: '2px solid #128CB8',
          background: 'var(--color-gray-light)',
          transition: '.2s ease-in-out',
        },
        '::after': {
          content: '""',
          backgroundImage: `url(${checkmarkBlue})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          top: '0',
          width: '16px',
          height: '16px',
          marginLeft: '10px',
        },
      },
    },
    {
      props: { variant: 'uploadImage' },
      style: {
        textTransform: 'capitalize',
        font: 'var(--font-body)',
        color: 'var(--color-blue)',
        border: '1px solid #E9EEF1',
        minWidth: '150px',
        minHeight: '150px',
        borderRadius: '6px',
      },
    },
    {
      props: { variant: 'uploadGlb' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        background: 'var(--color-blue)',
        borderRadius: '21px',
        fontWeight: '700',
        width: '156px',
        padding: '13px 19px 13px 19px',
        color: 'var(--color-white)',
        ':hover': {
          background: 'var(--color-blue-dark)',
        },
        '::before': {
          content: '""',
          backgroundImage: `url(${uploadGlb})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          top: '-1px',
          width: '16px',
          height: '16px',
          marginRight: '10px',
        },
        ':disabled': {
          background: 'rgba(144, 150, 153, 0.30)',
          color: '#FFF',
        },
      },
    },
    {
      props: { variant: 'uploadProfileImage' },
      style: {
        textTransform: 'capitalize',
        padding: '37px',
        minWidth: '182px',
        fontFamily: 'var(--font-main)',
        fontWeight: 'var(--weight-normal)',
        fontSize: 'var(--body-size)',
        lineHeight: 'var(--line-height-body)',
        color: 'var(--color-blue)',
        border: '1px dashed #909699',
      },
    },
    {
      props: { variant: 'loading' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        lineHeight: 'var(--line-height-sm)',
        border: '0',
        background: 'rgba(144, 150, 153, 0.3)',
        borderRadius: '21px',
        height: '42px',
        width: '156px',
        fontWeight: '700',
        padding: '0 24px',
        color: 'var(--color-white)',
        transition: '.2s ease-in-out',
      },
    },
    {
      props: { variant: 'previous' },
      style: {
        font: 'var(--font-body)',
        color: 'var(--color-dark)',
        textTransform: 'capitalize',
        padding: '6px 10px',
        '::before': {
          position: 'relative',
          top: '-1px',
          content: '""',
          backgroundImage: `url(${arrowLeft})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          border: '2px',
          width: '11px',
          height: '9px',
          marginRight: '12px',
        },
      },
    },
    {
      props: { variant: 'next' },
      style: {
        font: 'var(--font-body)',
        color: 'var(--color-dark)',
        textTransform: 'capitalize',
        padding: '6px 6px 6px 6px',
        '::after': {
          position: 'relative',
          top: '-1px',
          content: '""',
          backgroundImage: `url(${arrowRight})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          border: '2px',
          width: '11px',
          height: '9px',
          marginLeft: '10px',
        },
      },
    },
    {
      props: { variant: 'copyKey' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        whiteSpace: 'nowrap',
        color: 'var(--color-blue)',
        '::before': {
          content: '""',
          backgroundImage: `url(${copyIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          border: '2px',
          width: '16px',
          height: '16px',
          marginRight: '16px',
        },
      },
    },
    {
      props: { variant: 'copyKeyDone' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        whiteSpace: 'nowrap',
        color: 'var(--color-blue)',
        '::after': {
          content: '""',
          backgroundImage: `url(${checkmarkBlue})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          border: '2px',
          width: '16px',
          height: '16px',
          marginLeft: '16px',
        },
      },
    },
    {
      props: { variant: 'link' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        whiteSpace: 'nowrap',
        color: 'var(--color-blue)',
        textDecoration: 'underline',
        padding: '6px 6px',
        '::before': {
          content: '""',
          backgroundImage: `url(${arrowRightLink})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          width: '16px',
          height: '16px',
          marginRight: '10px',
        },
      },
    },
    {
      props: { variant: 'show-details' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        whiteSpace: 'nowrap',
        color: 'var(--color-blue)',
        padding: '6px 6px 6px 0',
        '::before': {
          content: '""',
          backgroundImage: `url(${showDetailsIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          width: '16px',
          height: '16px',
          marginRight: '10px',
        },
        ':hover': {
          color: 'var(--color-blue-dark)',
          transition: '.2s ease-in-out',
          background: 'transparent',
        },
      },
    },
    {
      props: { variant: 'cross' },
      style: {
        position: 'relative',
        textTransform: 'none',
        font: 'var(--font-body)',
        fontWeight: '500',
        paddingRight: '0',
        '::before': {
          content: '""',
          backgroundImage: `url(${crossIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          border: '2px',
          width: '16px',
          height: '16px',
          marginRight: '6px',
        },
        ':hover': {
          backgroundColor: 'unset',
        },
        '& span': {
          position: 'relative',
          top: '1px',
        },
      },
    },
  ],
}

export default muiButtons
